

















































































































































































































































































.PointsMall {
  .time {
    .el-date-editor {
      .el-range-separator {
        padding: 0;
      }
    }
  }
}
